import React from 'react';
import Landing from './pages/Landing.jsx'
import './styles/global.css';
function App() {
  return (
    <div >
      <Landing/>
    </div>
  );
}

export default App;
